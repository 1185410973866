<template>
    <div class="payment-all m-scroll">
        <header-bar title="Checkout">
            <img slot="left" src="@/assets/public/icon-fanhui_fff.png" alt="back" class="header-slot" @click="$router.go(-1)">
        </header-bar>
        <div class="shopping-main">
             <div class="shipping-address" v-if="addrs.id && !mailling" @click="show = true">
                <div class="add-left" >
                    <p>
                        <span>
                            <img src="@/assets/payment/icon_name@2x.png" alt="">
                            {{addrs.name}} {{addrs.surname}}
                        </span>
                        <span>
                            <img src="@/assets/payment/icon_tel@2x.png" alt="">
                             {{addrs.phone}}
                        </span>
                    </p>
                    <p>
                        <img src="@/assets/payment/icon_dress@2x.png" alt="">
                        {{addrs.addr}},{{addrs.city_arr ? addrs.city_arr.ename : addrs.city_name}},{{addrs.province_arr.ename}},{{addrs.code}} {{addrs.country_arr.ename}} 
                    </p>
                </div>
                <div class="add-right">
                    <img src="@/assets/payment/icon-fanhui@2x.png" alt="">
                </div>
                <img src="@/assets/payment/7@2x.png" alt="">
            </div>

            <div class="shipping-address" v-if="!addrs.id && !mailling" @click="$router.push('/en/address/edit')">
                <div class="add-left">
                    <p style="line-height:25px;margin-top:0">
                        <span>
                            <img src="@/assets/payment/icon_name@2x.png" alt="">
                            New Address
                        </span>
                    </p>
                </div>
                <div class="add-right">
                    <img src="@/assets/payment/icon-fanhui@2x.png" alt="">
                </div>
                <img src="@/assets/payment/7@2x.png" alt="">
            </div>

            <div class="store-item m-padding" v-for="(item, index) in order" :key="index">
                <div class="store-title van-hairline--bottom">
                    <img src="@/assets/payment/icon_dianpu@2x.png" alt="">
                    {{item.title}}
                    <span class="m-mooby" style="width:55px" v-show="item.is_presale == 1">Pre-Sale</span>
                    <span>({{item.goods_list.length}} {{item.goods_list.length == 1 ? 'item' : 'items'}})</span>
                    <!-- <span class="m-mooby" v-show="item.is_presale == 1">Pre</span> -->
                    <div class="isSelfpick" v-if="item.id == 1 && addrs.country != 507">
                        <van-switch v-model="mailling" size="20px" inactive-color="#dcdee0" />
                        <span>Pick up</span>
                    </div>
                </div>
                <div class="shipping" v-show="mailling">
                    <div class="shipping-left">
                        Address：Unit 133-11121 Horseshoe Way Richmond, BC, V7A5G7 Canada
                    </div>
                </div>
                <is-gift></is-gift>
                <div class="goods-list">
                    <div class="goods-item" v-for="(it, ind) in item.goods_list" :key="ind">
                        <img :src="it.sku_pic" alt="" class="goods-img">
                        <div class="goods-right">
                            <p class="van-multi-ellipsis--l2">{{it.goods_name}}</p>
                            <div class="sku">{{it.sku_title}}</div>
                            <div class="total">
                                <span>{{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{it.price}}</span>
                                ×{{it.number}}
                            </div>
                        </div>
                    </div>
                </div>
                <van-cell title="Coupon" is-link @click="handleCoupon()">
                    <template #right-icon>
                        <div class="coupons">
                            <span>{{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{item.coupon_type == 1 ? item.coupon_price : '0.00'}}</span>
                            <img src="@/assets/payment/price.png" alt="">
                        </div>
                        <img src="@/assets/payment/icon-fanhui@2x.png" alt="" >
                    </template>
                </van-cell>

                <van-cell title="Promo Code" is-link @click="handleCode(index)"  v-if="item.id == 1 && item.is_presale == 0">
                    <template #right-icon>
                        <div style="position:absolute;right:20px;color: #ED2A24;font-size: 10px;font-weight: 400;line-height:20px">
                            {{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{item.coupon_type == 2 ? item.coupon_price : '0.00'}}
                        </div>
                        <img src="@/assets/payment/icon-fanhui@2x.png" alt="" style="width:20px;height:20px">
                    </template>
                </van-cell>

                <van-cell title="Price"   :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.goods_price}`" :border="false" />
                <van-cell :title="'PST(' + (item.city_tax.pst*100).toFixed(0) + '%)'" :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.pst}`" v-if="item.pst != '0.00'" :border="false" />
                <van-cell :title="'GST(' + (item.city_tax.gst*100).toFixed(0) + '%)'" :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.gst}`" v-if="item.gst != '0.00'" :border="false" />
                <van-cell :title="'QST(' + (item.city_tax.qst*100).toFixed(0) + '%)'" :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.qst}`" v-if="item.qst != '0.00'" :border="false" />
                <van-cell :title="'HST(' + (item.city_tax.hst*100).toFixed(0) + '%)'" :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.hst}`" v-if="item.hst != '0.00'" :border="false" />
                <van-cell title="Taxes"    :value="`US$0.00(Tax Package)`" v-if="addrs.country == 507" :border="false" />
                <van-cell title="Shipping Fee" :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.mailling ? '0.00' : item.freight}`" :border="false" />
                <van-cell title="Coupon"   :value="`${Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}${item.coupon_price}`" :border="false" />
                <p class="store-total">Sub Total: {{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{item.total_price}}</p>
            </div>

            <!-- 抵扣 -->
            <div class="wallet-total" v-if="wallet">
                <img src="@/assets/payment/wallet.png" alt="">
                <span>Credits in Wallet <span>{{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{wallet.non_withdrawal_amount}}</span></span>
                <img src="@/assets/payment/select.png" alt="" v-if="wallet.wallet_type == 0" @click="setWallet(1)">
                <img src="@/assets/payment/active.png" alt="" v-else @click="setWallet(0)">
            </div>
        </div>
        <div class="cart-tools noselect van-hairline--top">
            <div class="price">
                <p>Total：{{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{totalPrice.total_price}}</p>
                <p v-if="wallet.wallet_type == 1">Credits in Wallet {{Object.keys(addrs).length != 0 ? addrs.country == 1 ? 'C$' : 'US$' : '$'}}{{totalPrice.wallet_deduction}}</p>
            </div>
            <div class="calc-order" @click="calcOrder">Submit</div>
        </div>



        <!-- 地址弹窗 -->
        <van-popup v-model="show" round position="bottom" :style="{ height: '50%' }" close-on-popstate >
            <p class="address-title">Modify Address</p>
            <ul class="list">
                <transition-group name="van-slide-down">
                    <li class="item" v-for="(it) in addressData" :key="it.id">
                        <van-radio :name="it.id" v-model="addrId" @click="changeAddress()">
                            <div class="name-phone">
                                <div>
                                    <img src="@/assets/address/icon_name@2x.png" alt="name">
                                    <span>{{it.name}}-{{it.surname}}</span>
                                    </div>
                                    <div>
                                    <img src="@/assets/address/icon_tel@2x.png" alt="name">
                                    <span>{{it.phone}}</span>
                                </div>
                            </div>
                            <div class="addr">
                                <img src="@/assets/address/icon_dress@2x.png" alt="">
                                <p>{{it.addr}},{{it.city_arr ? it.city_arr.ename : it.city_name}},{{it.province_arr.ename}},{{it.code}} {{it.country_arr.ename}}</p>
                            </div>
                            <div class="btns">
                                <div @click="handleEdit(it)">
                                <img
                                    src="@/assets/address/icon_bianji@2x.png"
                                    alt="edit"
                                /><span>Edit</span>
                                </div>
                            </div>
                        </van-radio>
                    </li>
                    
                </transition-group>
                    <li class="address-btn">
                        <van-button  type="info" block @click="handleAddress()">New Address</van-button>
                    </li>
                </ul>
        </van-popup>


         <!-- 优惠券弹窗弹窗 -->
        <van-popup v-model="couponShow" round position="bottom" :style="{ height: '50%' }" close-on-popstate >
            <p class="address-title">Select Coupon</p>
            <ul class="list">
                <van-radio-group v-model="coupon_id" direction="horizontal">
                    <transition-group name="van-slide-down"  v-if="couponList.length != 0">
                            <template v-for="(it) in couponList" >
                                <li class="item" v-if="it.show == 1" :key="it.coupon_info">
                                    <van-radio :name="it.coupon_info"  @click="changeCoupon(it.coupon_info)">
                                        <div class="name-phone">
                                            <img :src="it.pic.url" alt="coupon">
                                        </div>
                                    </van-radio>
                                </li>
                            </template>
                    </transition-group>
                </van-radio-group>
            </ul>
        </van-popup>

        <!-- 兑换弹窗 -->
        <van-popup v-model="exchangeShow" round position="bottom" :style="{ height: '35%' }" close-on-popstate >
            <p class="exchange-title">Promo Code</p>
            <div class="exchange-box">
              <textarea  ref="code"   placeholder="Please enter valid promo code information"  v-model="code"></textarea>
            </div>
            <div class="exchange-btn">
               <van-button @click="handleExchange()"  block round color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Confirm</van-button>
            </div>
        </van-popup>

        <SelfPick :isPickUp="mailling" @close="mailling = false" @submit="reLoadOrder" />
    </div>
</template>

<script>
import HeaderBar from '@/components/en/m-header.vue'
import { order, subOrder } from '@/api/zh/cart.js'
import IsGift from "@/components/en/is-gift.vue";
import { address } from '@/api/zh/mine'
import SelfPick from "./selfPick.vue"
export default {
    components: { HeaderBar, SelfPick, IsGift },
    data() {
        return {
            value: '1',
            addrs: {},
            mailling: false,
            order: [],
            totalPrice: {},
            exchangeCode: '',
            presale: 0,
            // 地址弹出层
            show: false,
            addressData: [],
            addrId: '',
             // 地址弹出层
            couponShow: false,
            conpun_index: 0,
            coupon_id: '',
            couponList: [],

            coupon_type: '',      //  1 使用优惠券  2 使用兑换码

            // 兑换码弹窗
            exchangeShow: false,
            code: '',
            pickUpInfo: {
                name: '',
                surname: '',
                tel: '',
                email: ''
            },
            wallet: {},
            notUseCoupon: false
        }
    },
    methods: {
        setWallet(flag) {
            // 是否使用钱包
            if(this.coupon_type == 2) {
                this.coupon_id = ''
            }
            if(this.coupon_type == 1 || this.coupon_id != '') {
                this.$notify({ type: "warning", message: "Coupon and wallet deduction cannot be used at the same time" });
                return false
            }
            this.coupon_type = 0
            this.wallet.wallet_type = flag
            this.getOrder()
        },
        // 获取订单详情
        getOrder() {
            let params = {
                buyId:this.$route.query.buyId,
                buyNum: this.$route.query.buyNum,
                couponType: this.coupon_type,
            }
            if(this.$route.query.haggle_id) {
                params.bargain_goods_sku_id = this.$route.query.haggle_id
            }
            if(this.mailling) {
                params.isPickUp = 1
            }else {
                params.isPickUp = 0
                params.addrId   = this.addrId
            }

            // 优惠券
            if(this.coupon_id != '') {
                params.isCoupon = this.coupon_id
            }

            if(this.wallet.wallet_type != undefined) {
                params.walletType = this.wallet.wallet_type
            }

            this.$store.state.load = true
            order(params).then(res => {
                if(res) {
                    this.wallet = res.wallet_info
                    this.totalPrice = res.total_info
                    this.order = []
                    if(res.addrs) {
                        this.addrs  = res.addrs
                        this.addrId = res.addrs.id
                    }
                    Object.keys(res.data).forEach(e => {
                        this.presale = res.data[e].is_presale
                        // this.coupon_type = res.data[e].coupon_type
                        if(res.data[e].coupon_info) {
                            if(res.data[e].coupon_type == 1) {
                                this.coupon_id = res.data[e].coupon_info - 0
                                if(this.coupon_id != 0) {
                                    this.notUseCoupon = false
                                }
                            }else {
                                this.coupon_id = res.data[e].coupon_info
                            }
                        } else {
                            this.notUseCoupon = true
                        }
                        if(res.data[e].coupon_list) {
                            this.couponList = res.data[e].coupon_list
                        }
                        this.order.push(res.data[e])
        
                    })
                }
            }).finally( () => {
                this.$store.state.load = false
            })
        },

        reLoadOrder(data = this.pickUpInfo) {
            // 修改自提 重新获取订单数据
            this.pickUpInfo = data
            this.getOrder()
        },

        calcOrder(e) {
            // 提交订单
            e.target.classList.add('calc-order-scale')
            setTimeout(() => {
                e.target.classList.remove('calc-order-scale')
            }, 150)
             let shareUid   = localStorage.getItem('Personal') ? localStorage.getItem('Personal') : 0
             let params = {
                buyId:this.$route.query.buyId,
                buyNum: this.$route.query.buyNum,
                presale: this.presale,
                shareUid: shareUid
            }
            if(this.$route.query.haggle_id) {
                params.bargain_goods_sku_id = this.$route.query.haggle_id
            }
            if(this.wallet.wallet_type) {
                params.wallettype = this.wallet.wallet_type
            } else {
                params.wallettype = 0
            }

            if(this.mailling) {
                params.isPickUp = 1
                params.pickUpInfo = this.pickUpInfo
            }else {
                params.isPickUp = 0
                params.addrId   = this.addrId
            }

            if(this.coupon_id && this.coupon_id != ''){
                params.isCoupon = this.coupon_id
            }
            if(this.coupon_type && this.coupon_id != 0) {
                params.couponType = this.coupon_type
            }else {
                if(this.coupon_id != 0) {
                    params.couponType = 1
                } else {
                    params.couponType = '';
                }
            }
            this.$store.state.load = true
            subOrder(params).then(res => {
                if(res) {
                    localStorage.setItem('order', JSON.stringify(this.order))
                    localStorage.setItem("order_total", JSON.stringify(this.totalPrice))
                    localStorage.setItem('order_address', JSON.stringify(this.addrs))
                    if(this.addrs.country == 507) {
                        this.$router.replace({ path: '/en/usPay', query: { title: res.pay_title } })
                    } else {
                        this.$router.replace({ path: '/en/pay', query: { title: res.pay_title } })
                    }
                } else {
                    let that = this
                    setTimeout(() => {
                        that.$router.push('/en/cart')
                    },1500)
                }
            }).finally( () => {
                this.$store.state.load = false
            })
        },

        // 编辑地址
        handleEdit(it) {
            window.sessionStorage.setItem("address", JSON.stringify(it));
            this.$router.push({ path: "/en/address/edit", query: { id: it.id } });
        },
        // 获取地址列表
        getAddress() {
            address().then(res => {
                if(res) {
                    this.addressData = res.data
                    // this.addrs  = res.data[0]
                    // this.addrId = res.data[0].id
                }
            })
        },

        // 改变地址
        changeAddress() {
            this.show = false
            this.getOrder()
        },


        // 显示优惠券弹窗
         handleCoupon() {

            if(this.couponList.length == 0) {
                this.$notify({ type: 'warning', message: 'No coupons available' })
                return false
            }
            this.couponShow = true
        },

        // 优惠券列表点击修改优惠券
        changeCoupon(id) {
            if(this.order[this.conpun_index].coupon_info == id) {
                this.order[this.conpun_index].coupon_info = 0
                this.order[this.conpun_index].coupon_type = 0
                this.coupon_id = ''
                this.coupon_type = 0
            } else {
                this.order[this.conpun_index].coupon_info = id
                this.order[this.conpun_index].coupon_type = 1
                this.coupon_type = 1
                if(this.wallet.wallet_type == 1) {
                    this.order[this.conpun_index].coupon_info = 0
                    this.order[this.conpun_index].coupon_type = 0
                    this.coupon_id = ''
                    this.coupon_type = 0
                    this.$notify({ type: "warning", message: "Coupon and wallet deduction cannot be used at the same time" });
                }
            }
            this.couponShow = false
            this.getOrder()
        },

        handleAddress() {
            let arr = [this.$route.query.buyId, this.$route.query.buyNum]
            this.$router.push(  {path: '/en/address/edit', query: { o: arr.join(',') }} )
        },
        
        // 显示兑换码弹窗
        handleCode(index) {
            this.conpun_index = index
            this.exchangeShow = true
        },

        // 确认兑换
        handleExchange() {
            if(this.wallet.wallet_type == 1) {
                this.$notify({ type: "warning", message: "Coupon and wallet deduction cannot be used at the same time" });
                return false
            }
            this.exchangeShow = false
            this.coupon_type = 2
            this.coupon_id = this.code
            this.getOrder()
            this.code = ''
        }

    },
    created() {
        localStorage.removeItem('isPay')
        this.getOrder()
        this.getAddress()
    },
    watch: {
        mailling(val) {
            if(!val) {
                this.getOrder()
            }
        }
    }
}
</script>

<style scoped lang="less">
@import './index.less';
</style>

<style lang="less">
.payment-all{
    .step-total{width:80px;height:20px;margin-right:-10px;
        button,input{height:100%;border-radius: 0}
        input{border:1px solid rgba(230,230,230,1);background-color: #fff;margin:0}
        button{width:20px;background-color: rgba(230,230,230,1)}
    }
    .m-cat-padding{padding:0}
    .m-cat-devider{
        margin:15px 0;
        font-size:18px;
        font-weight:500;
        color:rgba(15,30,51,1);
    }
    .address-title {
        height: 44px;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
        color: #666666;
        font-weight: 400;
        letter-spacing: 0.35px;
    }
    .address-btn {
        margin-top: 35px;
        padding: 0 35px;
    }
    .wallet-total{margin-top:-10px;width:100%;height:48px;background-color: #fff;margin-bottom:30px;
        >img{
            &:nth-of-type(1){width:20px;height:20px;margin:14px 12px}
            &:nth-of-type(2),&:nth-of-type(3){width:18px;height:18px;margin:15px 16px;float: right;}
        }
        >span{font-size: 14px;color:#666;vertical-align: 18px;
            >span{color:#ED2A24}
        }
    }
}

  .list {
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px 16px ;
    overflow-x: hidden;
    overflow-y: auto;
    /deep/ .van-radio__label {
      margin-left: 15px;
    }
    .item {
      margin-bottom: 12px;
      background-color: #fff;
      border-radius: 5px;
      padding: 0 20px 0 12px;
      box-sizing: border-box;
      box-shadow: 0px 4px 20px 0px rgba(8, 84, 191, 0.2);
      .btns {
        display: flex;
        align-items: center;
        text-align: right;
        justify-content: flex-end;
        padding-bottom: 2px;
        & > div {
            display: flex;
            align-items: center;
            margin-left: 14px;
            & > img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            }
            & > span {
            font-size: 12px;
            font-weight: 400;
            color: #000;
            display: inline-block;
            height: 18px;
            line-height: 18px;
            }
        }
      }
      .name-phone {
        width: 100%;
        height: auto;
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &>div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &>img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
          &>span {
            font-size:12px;
            font-weight:400;
            color: #000;
            display: inline-block;
            height: 17px;
            line-height: 17px;
          }
        }

      }
      .addr {
        width: 100%;
        padding-left: 20px;
        padding-bottom: 12px;
        position: relative;
        &>img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          left: 0;
        }
        &>p {
          font-size:12px;
          font-weight:400;
          color: #000;
          line-height: 17px;
          word-break: break-all;
        }
      }
      .edit {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btns {
          display: flex;
          align-items: center;
          &>div {
            display: flex;
            align-items: center;
            margin-left: 14px;
            &>span {
              font-size:14px;
              font-family: Avenir-Book, Avenir;
              font-weight: normal;
              color: #0854BF;
              display: inline-block;
              height: 17px;
              line-height: 17px;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
</style>
